@import '../../../styles/theme.scss';
.CustomPopupVolume {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  transition: 0.5s;

  &Blur {
    height: 100%;
    width: 100%;
    opacity: 0.6;
    background-color: var(--background_color_primary);
  }

  &Container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 97%;
    max-width: 420px;
    background-color: var(--background_color_popup);
    transform: translate(-50%, -50%);
    border-radius: 12px;
    padding: 12px;

    &Header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 16px;
      border-bottom: 1px solid var(--color_secondary);

      p {
        font-size: $font_size_x_medium;
        line-height: $font_size_x_medium;
        padding-right: 16px;
      }
      .volume {
        height: 24px;
        width: 24px;
      }
      .btn_close {
        position: absolute;
        right: 0;
      }
    }

    &Music {
      background-color: var(--background_color_horizontal_navbar);

      &Button {
        margin: 10px 0 28px;
        display: flex;
        justify-content: center;
        img {
          margin: 0 20px;
          height: 40px;
          width: 40px;
          cursor: pointer;
        }
      }

      &List {
        padding: 0 8px;
        max-height: 360px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 3px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: var(--color_secondary);
        }

        &::-webkit-scrollbar-track {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px var(--text_color_placeholder);
        }
        &Item {
          padding: 8px 16px;
          margin: 0 0 4px;
          background-color: var(--background_color_menu);
          min-height: 56px;
          display: flex;
          align-items: center;

          &:hover {
            cursor: pointer;
            background-color: var(--background_color_tertiary);
          }
          span {
            display: -webkit-box;
            -webkit-line-clamp: 2; /* Giới hạn số dòng là 2 */
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }

        .active {
          background-color: var(--background_color_tertiary) !important;
        }
      }
    }

    &Range {
      padding: 16px;
      .title,
      .value {
        color: var(--white_color);
      }

      input[type='range'] {
        /* removing default appearance */
        -webkit-appearance: none;
        appearance: none;
        /* creating a custom design */
        width: 100%;
        cursor: pointer;
        outline: none;
        border-radius: 15px;
        /*  overflow: hidden;  remove this line*/

        /* New additions */
        height: 6px;
        background: #ccc;
      }

      /* Thumb: webkit */
      input[type='range']::-webkit-slider-thumb {
        /* removing default appearance */
        -webkit-appearance: none;
        appearance: none;
        /* creating a custom design */
        height: 15px;
        width: 15px;
        background-color: var(--color_secondary);
        border-radius: 50%;
        border: none;
        transition: 0.2s ease-in-out;
      }

      /* Thumb: Firefox */
      input[type='range']::-moz-range-thumb {
        height: 15px;
        width: 15px;
        background-color: var(--color_secondary);
        border-radius: 50%;
        border: none;
        transition: 0.2s ease-in-out;
      }

      /* Hover, active & focus Thumb: Webkit */

      input[type='range']::-webkit-slider-thumb:hover {
        box-shadow: 0 0 0 10px rgba(255, 85, 0, 0.1);
      }
      input[type='range']:active::-webkit-slider-thumb {
        box-shadow: 0 0 0 13px rgba(255, 85, 0, 0.2);
      }
      input[type='range']:focus::-webkit-slider-thumb {
        box-shadow: 0 0 0 13px rgba(255, 85, 0, 0.2);
      }

      /* Hover, active & focus Thumb: Firfox */

      input[type='range']::-moz-range-thumb:hover {
        box-shadow: 0 0 0 10px rgba(255, 85, 0, 0.1);
      }
      input[type='range']:active::-moz-range-thumb {
        box-shadow: 0 0 0 13px rgba(255, 85, 0, 0.2);
      }
      input[type='range']:focus::-moz-range-thumb {
        box-shadow: 0 0 0 13px rgba(255, 85, 0, 0.2);
      }

      /*=============
Aesthetics 
=========================*/

      body {
        font-family: system-ui;
      }

      h1 {
        color: #4b4949;
        text-align: center;
      }

      .wrapper {
        color: #4b4949;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
      }

      .range {
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 1rem;
        max-width: 500px;
        width: calc(100% - 80px);
        // background: #fff;
        padding: 0px 10px;
      }

      .value {
        font-size: $font_size_x_medium;
        width: 50px;
        text-align: center;
      }
    }
  }
}
