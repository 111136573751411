.Loader {
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  left: 0px;
  top: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
}
