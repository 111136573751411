@import '../../../../../../assets/styles/helpers/variables';
@import '../../../styles/theme.scss';

.navGameBoV3 {
  .navDesktop {
    @include display-flex;

    &Item {
      position: relative;
      margin-right: 20px;
      cursor: pointer;

      @media only screen and (max-width: 1280px) {
        margin-right: 12px;
      }

      @media #{$media-tablet-horizontal} {
        margin-right: 14px;
      }

      &:last-child {
        margin-right: 0;
      }

      &Parent {
        width: 100%;
        @include display-flex;
        align-items: center;

        &Icon {
          height: 14px;
          width: 14px;
          margin-right: 3px;
          margin-bottom: 3px;
        }

        &Name {
          font-size: $font_size_normal;
          color: var(--text_color_primary);

          &Active {
            color: var(--color_secondary);
          }
        }
      }

      &Children {
        display: none;
      }

      &:hover {
        .navDesktopItemChildren {
          position: absolute;
          display: block !important;
          padding: 8px;
          background-color: var(--text_color_tertiary);
          z-index: 10;
          min-width: 150px;
          bottom: -12px;
          transform: translateY(100%);

          &::before {
            position: absolute;
            content: '';
            height: 16px;
            width: 150px;
            background-color: transparent;
            top: 0;
            left: 0;
            transform: translateY(-100%);
          }

          &Item {
            @include display-flex;
            align-items: center;
            padding: 8px;

            &:hover {
              background-color: var(--background_color_tertiary);
            }

            img {
              margin-right: 8px;
              width: 10px;
              height: 10px;
            }

            span {
              font-weight: 600;
              font-size: $font_size_normal;
              color: var(--text_color_primary);
            }
          }
        }
      }
    }
  }
}