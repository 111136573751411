@import '../assets/styles/helpers/variables';

.background {
  &-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

@font-face {
  font-family: 'arial';
  src: url('../assets/fonts/arial/arial.ttf');
}

// body {
//   color: #222222;
//   // font-family: 'SFU Helvetica', serif !important;
// }

.btn {
  &-main {
    background: #15264b !important;
    color: #313131 !important;
    border-radius: 4px !important;
    padding: 10px 28px !important;
    border: 1px solid #24345a !important;
    height: 36px !important;
    line-height: 16px !important;
    font-weight: 600;
  }

  &-red {
    background: #f5493a !important;
    color: #ffffff !important;
    border-radius: 8px !important;
    padding: 6px !important;
    height: 32px !important;
    line-height: 1 !important;
    font-size: 14px;
    font-weight: 700;

    // &:hover {
    //   background: #F5493A !important;
    //   opacity: .8 !important;
    // }
  }

  &-outline {
    &-main {
      background: #fff !important;
      color: $main-color !important;
      border-radius: 8px !important;
      border: 1px solid $main-color;
      padding: 6px !important;
      height: 32px !important;
      line-height: 1 !important;
      font-size: 14px;
      font-weight: 700;

      // &:hover {
      //   background: #fff !important;
      //   opacity: .8 !important;
      // }
    }
  }

  &-submit {
    &.ant-btn-default {
      font-size: 14px;
      font-weight: 700;
      line-height: 1.25;
      text-align: center;
      text-transform: uppercase;
      color: var(--text_color_btn) !important;
      background: var(--bg_button) !important;
      border: none !important;
      border-radius: 4px !important;
      cursor: pointer;
      height: 34px !important;
      min-width: 266px;
      padding: 0 10px;

      @media #{$media-tablet-vertical} {
        width: 100%;
        min-width: 100%;
      }
    }
  }
}

.container {
  &-input {
    position: relative;

    .input {
      border: 1px solid rgba(255, 255, 255, 0.08);
      border-radius: 10px;
      text-align: left;
      height: 48px;
      background: #26264e;
      color: #ffffff;

      &:hover,
      &:focus {
        outline: none;
        box-shadow: none;
        background: none;
      }

      &::placeholder {
        color: #aeb9c7;
        opacity: 1;
        /* Firefox */
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input::-webkit-outer-spin-button {
    }
  }
}

.w-0 {
  width: 0 !important;
}

.ant-form-item-explain-error {
  padding: 5px 0;
}

.cursor-pointer {
  cursor: pointer;
}

//style sweet-alert
.swal-modal {
  background: #303131;
  border: 1px solid #535353;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  padding: 16px;

  &::after {
    position: absolute;
    content: '';
    height: 5px;
    width: 80%;
    background: linear-gradient(180deg, #feb914 0%, #ffca4d 100%);
    border-radius: 0 0 8px 8px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .swal-icon--success {
    //background-image: url("../assets/bo-icons/avatar.png") !important;

    .swal-icon--success__hide-corners {
      display: none;
    }

    &::before {
      background: transparent;
    }

    &::after {
      background: transparent;
    }
  }

  .swal-text {
    color: var(--white_color);
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .swal-footer {
    display: flex;
    gap: 16px;
    width: 100%;
    text-transform: uppercase;

    .swal-button-container {
      width: 100%;

      .swal-button {
        width: 100%;
        color: #313133;
        text-transform: uppercase;
      }

      .swal-button--cancel {
        background: #666666;
        border: 1px solid#666666;
        border-radius: 4px;
      }

      .swal-button--confirm {
        background: linear-gradient(180deg, #feb914 0%, #ffca4d 100%);
        border: 1px solid #f1a57b;
        border-radius: 4px;
      }
    }
  }
}

.layoutCustomAll {
  @include display-flex;
  height: 100%;
  position: relative;
}

.layoutCustomLeft {
  width: calc(100% - 240px);
  padding: 16px;

  @media #{$media-tablet-vertical} {
    width: 100%;
  }
}

.layoutCustomRight {
  width: 240px;
  padding: 16px 0 16px 0;
  border-left: 1px solid var(--background_color_tertiary);
  background-color: var(--border_color_community);

  @media #{$media-tablet-vertical} {
    display: none;
  }
}

.backgroundBtnOrange {
  background: linear-gradient(180deg, #fc8b28 0%, #ff5857 100%) !important;
}

.backgroundBtnGray {
  background: #393c4d !important;
}

.isDisable {
  pointer-events: none;
  opacity: 0.6;
}

button.isDisable {
  cursor: not-allowed !important;
}

.title-page {
  font-weight: 700;
  font-size: 30px;
  line-height: 1.25;
  color: var(--color_secondary);
  padding-bottom: 30px;

  @media #{$media-tablet-vertical} {
    color: var(--color_secondary);
    font-size: 20px;
  }
}

.form-input {
  background-color: var(--bg_form_input) !important;
  color: #ffffff !important;
  border: none;
  border-radius: 4px !important;

  &:disabled {
    color: var(--color_input_disable) !important;
  }

  &:hover {
    border-color: #40a9ff !important;
  }

  &::placeholder {
    color: var(--text_color_placeholder);
  }
}

.customLoadingChartAbsolute {
  position: absolute;
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #feb914;
  width: 60px;
  height: 60px;
  -webkit-animation: spinCustom 2s linear infinite;
  /* Safari */
  animation: spinCustom 2s linear infinite;
  top: calc(50% - 36px);
  left: calc(50% - 36px);
  transform: translate(-50%, -50%);
}

@-webkit-keyframes spinCustom {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinCustom {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ant-tooltip-inner {
  color: #212121 !important;
}

.check-show-mobile {
  @media (max-width: 900px) {
    display: none;
  }
}

.textColorWarning {
  color: var(--text_color_error);
}

.input-text-hidden {
  padding: 10px 12px !important;
  border-radius: 4px;
  border-color: transparent;

  &,
  &.ant-input,
  input.ant-input {
    color: var(--white_color);
    background-color: var(--background_color_login);
    background: var(--background_color_login);
  }

  &.ant-input:hover,
  input.ant-input:hover {
    border-radius: 4px;
  }

  &::placeholder,
  &.ant-input::placeholder,
  input.ant-input::placeholder {
    color: var(--text_color_placeholder) !important;
    font-size: 14px !important;
    line-height: 1.25 !important;
  }

  &:disabled,
  &.ant-input:disabled,
  input:disabled,
  input.ant-input:disabled {
    color: var(--color_input_disable);
    border-color: transparent;
  }
}

.input-has-button {
  padding: 6px 12px !important;
}

.btn-icon-close {
  width: 27px;
  min-width: 27px;
  height: 27px;
  min-height: 27px;
  background-color: var(--bg_color_btn_close);
  border-radius: 9px;

  img {
    width: 15px;
    min-width: 15px;
    height: 15px;
    min-height: 15px;
  }
}

.text-validate-input {
  color: var(--text_color_warning);
  font-family: var(--font_family);
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.25;
  margin-bottom: 12px;
}

.text-color-primary {
  color: var(--color_primary) !important;
}

.input-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.25;
  color: var(--white_color);
  padding-bottom: 6px;
}

.text-highlight {
  font-size: 22px;
  font-weight: bold;
  color: red !important;
}

.colorPrimary {
  color: var(--color_primary) !important;
}

.colorSecondary {
  color: var(--color_secondary) !important;
}

.colorWarnPrimary {
  color: var(--color_warn_primary) !important;
}
.backgroundPrimary {
  background-color: var(--color_primary) !important;
  background: var(--color_primary) !important;
}

.backgroundSecondary {
  background-color: var(--color_secondary) !important;
  background: var(--color_secondary) !important;
}

.backgroundWarnPrimary {
  background-color: var(--color_warn_primary) !important;
  background: var(--color_warn_primary) !important;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}

.commonPagination {
  margin-top: 20px;
  display: flex;
  justify-content: right !important;
  color: var(--text_color_primary);

  @media #{$media-tablet-vertical} {
    justify-content: center !important;
    padding-right: 16px;
  }

  .ant-pagination-item-ellipsis {
    color: var(--text_color_primary) !important;
  }

  li {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.25);
  }

  .ant-pagination-item-link {
    color: var(--text_color_primary);
    background: transparent !important;
    border: none;
  }

  .ant-pagination-item-active {
    background: var(--color_secondary);
    color: #0e182f;
    border: none;
  }
}
