:root,
html[data-theme='light'] {
  --background_color_primary: white;
  --text_color_primary: black;
  --background_color_recharge: #9f9f9f;
  --background_color_chatbox: #9f9f9f;
  --background_color_chatbox_input: #686868;

  //font-family
  --font_family: 'arial', sans-serif;
}

:root,
html[data-theme='dark'] {
  --text_color_primary: #ffffff;
  --text_color_secondary: #9f9f9f;
  --text_color_tertiary: #3e3e3e;
  --text_color_warning: #ff0000;
  --background_color_primary: #171a1e;
  --background_color_secondary: rgba(57, 56, 56, 0.21);
  --background_color_tertiary: #555555;
  --background_color_menu: #313131;
  --background_color_recharge: #212121;
  --background_color_horizontal_navbar: #212121;
  --border_color_primary: #3e3e3e;
  --color_primary: #36d572;
  --color_primary_darker: rgba(54, 213, 114, 0.2);
  --color_warn_primary: #f6465c;
  --color_warn_primary_darker: rgba(255, 74, 74, 0.14);
  --color_success_primary_darker: rgb(53 60 50 / 81%);
  --color_secondary: #feb914;
  --color_secondary_darker: #483509;
  --color_nav_active: #ffc53b;
  --color_nav_inactive: #ffffff;
  --bg_form_input: #2c2c2c;
  --color_input_disable: #515151;
  --bg_button: linear-gradient(180deg, #feb914 0%, #ffca4d 100%);
  --background_color_transparent_login: rgba(26, 26, 26, 0.65);
  --background_color_login: #373737;
  --background_color_popup: #303131;
  --text_color_placeholder: #686868;
  --text_color_error: #ff0303;
  --border_color_community: #2e2e2e;
  --background_color_chatbox: #1f2021;
  --background_color_chatbox_input: #686868;
  --background_color_chatbox_mesage: #0870d9;
  --background_color_chatbox_cskh: #f3f3f3;
  --background_color_community: #272727;
  --color_white_gray: #8f8f8f;
  --color_disable: #d9d9d9;
  --bg_color_btn_close: #1d1d1d;
  --white_color: #ffffff;
  --text_color_btn: #313131;
  --text-color-note: #7a7a7a;
  --text-color-note-danger: #ff4a4a;

  //font-family
  --font_family: 'arial', sans-serif;
}

$font_size_xl: 20px;
$line_height_xl: 22px;
$font_size_lg: 18px;
$line_height_lg: 20px;
$font_size_xmd: 16px;
$line_height_xmd: 18x;
$font_size_md: 14px;
$line_height_md: 16x;
$font_size_sm: 12px;
$line_height_sm: 14px;
$font_size_xs: 10px;
$line_height_xs: 12px;
$font_size_xxs: 9px;
$line_height_xxs: 11px;

$font_size_extra: 42px;
$font_size_x_large: 30px;
$font_size_large: 24px;
$font_size_x_medium: 20px;
$font_size_medium: 16px;
$font_size_normal: 14px;
$font_size_small: 12px;
