@import './theme.scss';
@import '../../../../assets/styles/helpers/variables';

.gameBoV3 {
  .mainLayout {
    display: flex;
    width: 100%;

    &Left {
      width: calc(100% - 328px);

      @media #{$media-tablet-horizontal} {
        width: 100%;
      }

      &Action {
        display: flex;
        justify-content: stretch;

        @media (min-width: 750px) and (max-width: 835px) {
          min-height: unset;
        }

        &Market {
          width: 288px;

          @media #{$media-tablet-horizontal} {
            display: none;
          }
        }

        &Chart {
          width: calc(100% - 288px);
          border: 1px solid var(--border_color_primary);
          border-top: none;
          border-bottom: none;

          @media #{$media-tablet-horizontal} {
            width: 100%;
          }
        }
      }
    }

    &Right {
      width: 328px;

      @media #{$media-tablet-horizontal} {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 1160px) {
  .mainLayoutRight {
    height: calc(100vh - 60px);
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
@media only screen and (max-width: 800px) {
  .gameBoV3 {
    .mainLayout {
      &Left {
        width: 100%;

        &Action {
          &Chart {
            width: 100%;
          }
        }
      }
    }
  }
}

.colorPrimary {
  color: var(--color_primary) !important;
}

.colorWarnPrimary {
  color: var(--color_warn_primary) !important;
}

.backgroundPrimary {
  background-color: var(--color_primary_darker) !important;
}

.backgroundWarnPrimary {
  background-color: var(--color_warn_primary_darker) !important;
}

.backgroundPrimaryMain {
  background-color: var(--color_primary) !important;
}
.backgroundGreenDarker {
  background-color: #24ae6e !important;
}

.backgroundWarnPrimaryMain {
  background-color: var(--color_warn_primary) !important;
}

.borderPrimary {
  border: 1px solid var(--color_primary);
}

.borderWarnPrimary {
  border: 1px solid var(--color_warn_primary);
}

.backgroundTertiary {
  background-color: var(--background_color_tertiary) !important;
}

.borderTertiary {
  border: 1px solid var(--background_color_tertiary);
}

.borderColorSecondary {
  border: 1px solid var(--color_secondary) !important;
}

.colorColorSecondary {
  color: var(--color_secondary) !important;
}

.isDisabled {
  pointer-events: none !important;
  cursor: not-allowed !important;
  opacity: 0.5 !important;
  background-color: #666666;
}
