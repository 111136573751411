@import '../../assets/styles/helpers/variables';

.header {
  .head {
    padding: 12px 20px;
    background: #0f152f;

    &Container {
      margin: auto;

      @media #{$media-ipad} {
        padding: 0 10px;
      }
    }

    @media #{$media-tablet-vertical} {
      padding: 4px 0;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 46px;
      background-color: rgb(29, 29, 66);
      z-index: 998;
    }
  }

  .header-link {
    text-transform: uppercase;
    color: $COLOR_WHITE;
    text-decoration: none;
    @include display-flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 100%;
    min-width: 108px;
    padding: 0 8px;
    background: $COLOR_LINK;
    border: 1px solid $COLOR_LINK_LIGHT;
    border-radius: 4px;
    font-size: $TEXT_12;
    line-height: 1.3;
    font-weight: 600;

    &.register-link {
      background: $COLOR_LINK_BACKGROUND;
      border: 1px solid $COLOR_LINK_SUB;
    }
  }

  .header-notify {
    cursor: pointer;
    display: flex;
    padding: 7px;
    border: 1px solid #24345a;
    border-radius: 12px;

    &-active {
      position: absolute;
      right: 0;
      top: 0;
      width: 12px;
      height: 12px;
      background: linear-gradient(180deg, #ed811d 0%, #e83d3c 100%);
      border: 0.4px solid #f1a57b;
      border-radius: 50%;
    }

    &-icon {
      width: 20px;
      height: 20px;
    }

    &-text {
      font-size: 12px;
      line-height: 16px;
      color: #b1bedd;
      font-weight: 700;
      margin-left: 4px;
    }

    &-mobile {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      display: none;
      background: #0e182f;
      padding: 20px;

      @media #{$media-ipad} {
        display: flex;
      }

      &-icon {
        width: 24px;
        height: 24px;
        margin-right: 18px;
      }

      &-title {
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        text-transform: uppercase;
        color: #fdfefd;
        margin-bottom: 0;
      }
    }

    &-container {
      position: absolute;
      z-index: 10;
      top: 43px;
      left: -300px;
      //right: -240px;
      background: #142042;
      border-radius: 12px;
      transition: 0.3s;
      padding: 18px 16px 0;
      overflow-y: scroll;
      max-height: 516px;

      @media #{$media-tablet-vertical} {
        left: -300px;
        right: 0;
      }

      @media #{$media-ipad} {
        position: fixed;
        transition: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        max-height: 100vh;
        border-radius: 0;
        padding: 80px 16px 0;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      &-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #f08a2b;
        margin-right: 9px;
      }

      &-number {
        min-width: 22px;
        line-height: 22px;
        font-weight: 700;
        font-size: 14px;
        color: #fdfefd;
        padding: 0 5px;
        background: linear-gradient(180deg, #ff5857 0%, #ff7918 100%);
        border-radius: 8px;
        text-align: center;
      }

      &-content {
        padding: 26px 0;
      }

      .watched {
        background: transparent;
      }

      &-element {
        display: block;
        padding: 16px 12px;
        margin-bottom: 4px;
        border-radius: 4px;
        background: #1e2c56;
        text-decoration: none;

        &-title {
          margin-bottom: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: #f08a2b;
        }

        &-circle {
          height: 9px;
          min-width: 9px;
          border-radius: 50%;
          margin-left: 16px;
          background: linear-gradient(180deg, #fdb91f 0%, #fc8b28 100%);
        }

        &-watched {
          margin-bottom: 12px;
          margin-right: 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-size: 16px;
          line-height: 22px;
          color: #fdfefd;
        }

        &-description {
          font-size: 14px;
          line-height: 19px;
          color: #b1b7cf;
          margin-bottom: 12px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        &-time {
          font-size: 14px;
          line-height: 19px;
          color: #b1b7cf;
          margin-bottom: 0;
        }
      }
    }

    &-show {
      height: unset;
    }

    &-hide {
      height: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .chart {
    &Volume {
      cursor: pointer;
      background: #15264b;
      border: 1px solid #24345a;
      border-radius: 10px;
      padding: 3px;

      //margin-left: 8px;
      &Image {
        width: 30px;
        height: 30px;
      }
    }

    &BtnMenu {
      display: none;

      @media #{$media-tablet-vertical} {
        display: block;
        width: 36px;
        height: 36px;
      }
    }

    &BtnLogo {
      background: #ffffff;
      width: 84px;
      height: 40px;
      border-radius: 12px;
      padding: 0;
      text-decoration: none !important;
      @include display-flex;
      background: transparent;
      cursor: pointer;

      @media #{$media-tablet-vertical} {
        display: none;
      }
    }

    &Btn {
      color: #ffffff;
      text-decoration: none !important;
      @include display-flex;
      padding: 8px;
      background: #15264b;
      border: 1px solid #24345a;
      border-radius: 16px;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      //margin-right: 8px;
      cursor: pointer;

      &Image {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.layout {
  ul,
  ol,
  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &__user {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--white-color);
    display: flex;

    svg,
    img {
      margin-right: 10px;
    }

    &-logout {
      color: var(--main-eight-color) !important;
    }
  }

  &__avatar {
    width: 20px !important;
    border-radius: 100%;
  }

  &__header {
    background-color: var(--main-eleven-color) !important;
    border-bottom: 1px solid var(--main-ten-color);

    .header-right {
      margin-top: -8px;
    }

    li {
      padding-right: 40px;

      a {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: var(--white-color);
        text-decoration: unset;
      }

      a.active {
        color: var(--green-color);
      }
    }
  }

  &__login {
    color: var(--green-color);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    border: 1px solid var(--green-color);
    box-sizing: border-box;
    border-radius: 2px;
    padding: 8px 16px;
    margin-right: 10px;
  }

  &__register {
    background: var(--green-color);
    border: 1px solid var(--green-color);
    box-sizing: border-box;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
    color: var(--white-color);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 16px;
  }

  &__footer {
    padding: 61px 90px;
    border-top: 1px solid var(--main-eleven-color);
    background: var(--main-eleven-color);
  }

  &__text {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: var(--green-color);
    margin-bottom: 20px;

    &-second {
      font-size: 16px;
      line-height: 24px;
      color: var(--white-color);
      margin-bottom: 20px;
      font-weight: normal;
    }

    &__logo {
      margin-bottom: 60px;
    }
  }

  &__content {
    display: flex;
    padding: 40px 10px;

    .ant-upload {
      width: 100% !important;
    }
  }

  @media (min-width: 978px) {
    &__content {
      padding: 40px 90px;
    }
  }

  @media (max-width: 768px) {
    &__content {
      padding-bottom: 60px;
      padding-top: 0 !important;
    }

    .lg-hidden {
      display: unset !important;
    }

    &__footer {
      padding: 10px 10px;
    }

    .header-right {
      display: none;
    }
  }
}

.lg-hidden {
  display: none !important;
}

.navigation {
  & > .active {
    color: var(--main-color) !important;
  }
}

.header-mobile {
  &__container {
    position: relative;

    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.muan {
  background-color: white;
  border-radius: 35px;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 52px;
  height: 51px;
  bottom: 25px;
}

.centertxt {
  bottom: 20px;
  position: absolute;
}

.footer {
  &__item {
    min-width: 60px;
  }

  &__mobile {
    max-width: 384px;
    margin: auto;
    border: 1px solid rgba(0, 0, 0, 0.06);
  }

  @media (max-width: 768px) {
    &__mobile {
      max-width: 100%;
    }
  }
}
