@import '../../../../../../assets/styles/helpers/variables';
@import '../../../styles/theme.scss';

.chart {
  .chartPlayActionButton span {
    padding-top: 2px;
  }

  .activeItemButton {
    border: 0.79597px solid var(--color_secondary) !important;

    span {
      color: var(--color_secondary);
    }
  }

  .showPlayRecordTurn,
  .showPlayRecordNotification,
  .showPlayRecordWin,
  .showPlayRecordLose,
  .showModalBetWinMission,
  .showModalBetLoseMission {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 600;
    color: var(--color_secondary);
    padding: 12px 40px;
    min-width: 300px;
    z-index: 500;
    border-top: 2px solid var(--color_secondary);
    border-bottom: 2px solid var(--color_secondary);
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(90, 34, 34, 0.6), rgba(90, 34, 34, 0.6), rgba(255, 255, 255, 0));
    text-align: center;
    pointer-events: none;
    width: 70%;

    @media only screen and (max-width: 501px) {
      font-size: $font_size_x_medium;
      width: 90%;
    }
  }

  .showPlayRecordWin {
    border-top-width: 3px;
    border-bottom-width: 3px;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, transparent, var(--color_primary), var(--color_primary), transparent);
    color: var(--color_primary);
    width: unset;
  }

  .showPlayRecordTurn {
    border-top-width: 3px;
    border-bottom-width: 3px;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, transparent, var(--color_secondary), var(--color_secondary), transparent);
    color: var(--color_secondary);
    width: unset;
  }
  .showModalBetWinMission {
    border-top-width: 3px;
    border-bottom-width: 3px;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, transparent, var(--color_primary), var(--color_primary), transparent);
    color: var(--color_primary);
    width: unset;
  }
  .showModalBetLoseMission {
    border-top-width: 3px;
    border-bottom-width: 3px;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, transparent, var(--color_warn_primary), var(--color_warn_primary), transparent);
    color: var(--color_warn_primary);
    width: unset;
  }

  .showPlayRecordLose {
    border-top-width: 3px;
    border-bottom-width: 3px;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, transparent, var(--text_color_warning), var(--text_color_warning), transparent);
    color: var(--text_color_warning);
    width: unset;
  }

  &Candlestick {
    position: relative;
    padding: 0px 8px 4px 20px;
    border-bottom: 1px solid var(--border_color_primary);
    @media only screen and (max-width: 769px) {
      padding-left: unset;
      padding-right: unset;
      margin-left: 14px;
      position: relative;
      .indicatorMobile {
        z-index: 8;
        @media only screen and (max-width: 768px) {
          display: block !important;
        }
      }
      .lineRedStand {
        position: absolute;
        width: 5px;
        height: calc(100% - 12px);
        left: -10px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 5px;
        background-color: var(--color_warn_primary);
        z-index: 8;
      }
      .lineGreenStand {
        background-color: var(--color_primary);
        transition: 0.5s;
        width: 5px;
        border-radius: 5px;
        z-index: 8;
      }
      .moneyIndicator {
        position: absolute;
        height: calc(100% - 8px);
        top: 50%;
        transform: translateY(-50%);
        z-index: 8;
        .top,
        .bot {
          font-size: $font_size_small;
          font-weight: 600;
          background-color: rgba(23, 26, 30, 0.55);
        }
      }
    }
  }

  &Play {
    padding: 8px 12px 4px;
    background-color: var(--background_color_secondary);
    @include display-flex;
    justify-content: space-between;
    align-items: center;

    @media #{$media-tablet-horizontal} {
      padding: 16px 24px 0px;
    }

    &Mobile {
      display: none;
    }

    &Action {
      width: calc(33.333% - 8px);
      @include display-flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;

      @media #{$media-tablet-horizontal} {
        width: calc(40% - 12px);
      }

      @media screen and (min-width: 1161px) and (max-width: 1300px) {
        width: calc(38% - 8px);
      }

      &PeriodDetail {
        display: none;
        font-size: $font_size_normal;
        align-items: center;
        color: var(--text_color_primary);
        margin-right: 8px;
        @media #{$media-ipad} {
          font-size: $font_size_small;
          margin-right: 12px;
        }
      }

      &Wallet {
        margin-bottom: 4px;
        display: flex;

        .chartPlayParameterValueProfit,
        .chartPlayParameterValueMoney {
          display: none;
        }
        p {
          font-weight: 400;
          font-size: $font_size_normal;
          display: flex;
          align-items: center;
          color: var(--text_color_primary);
          @media #{$media-ipad} {
            font-size: $font_size_small;
          }
        }

        span {
          font-weight: 500;
          color: var(--color_secondary);
          margin-left: 3px;
        }
      }

      &Amount {
        @include display-flex;
        justify-content: center;
        align-items: center;
        margin-right: 30px;
        @media screen and (min-width: 1061px) and (max-width: 1300px) {
          margin-right: 0px;
        }
        @media #{$media-tablet-horizontal} {
          margin-right: 0;
          justify-content: space-between;
        }

        &Title {
          margin-right: 6px;
          font-size: $font_size_small;
          color: var(--text_color_primary);
          min-width: 32px;

          @media only screen and (max-width: 1300px) {
            display: none;
          }
        }

        &Value {
          @include display-flex;
          justify-content: space-between;
          align-items: center;
          width: calc(100% - 30px);

          @media #{$media-tablet-horizontal} {
            margin-right: 0;
            width: calc(100%);
          }

          &Input {
            @media only screen and (max-width: 769px) {
              min-width: 140px !important;
            }
          }

          span {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            width: 30px;
            background: var(--background_color_tertiary);
            border-radius: 4px;
          }

          .dollar {
            color: var(--background_color_primary);
            position: absolute;
            top: 4px;
            left: 2px;
            font-weight: 600;
            font-size: $font_size_x_medium;
            line-height: $font_size_x_medium;
            @media only screen and (max-width: 500px) {
              top: 7px !important;
            }
          }

          input {
            width: 100%;
            background: var(--text_color_primary);
            border: 0.860553px solid var(--text_color_primary);
            border-radius: 3.44221px;
            height: 30px;
            color: var(--background_color_primary);
            font-weight: 800;
            font-size: $font_size_x_medium;
            line-height: $font_size_x_medium;
            padding: 0 12px;
          }

          img {
            position: absolute;
            right: 6px;
            top: 8px;
            padding: 2px;
            cursor: pointer;
            height: 15px;
            width: 15px;
          }

          .select {
            position: absolute;
            z-index: 100;
            background-color: var(--text_color_tertiary);
            bottom: 0;
            left: 0;
            right: 0;
            transform: translateY(100%);
            padding: 4px;
            max-height: 200px;
            overflow-y: auto;

            &::-webkit-scrollbar {
              width: 4px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: #f1f1f1;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #888;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #555;
            }

            p {
              cursor: pointer;
              padding: 6px;
              font-size: $font_size_medium;
              color: var(--text_color_primary);
              display: block;

              &:hover {
                background-color: var(--background_color_tertiary);
              }
            }
          }
        }
      }

      &Button {
        @include display-flex;
        justify-content: space-between;
        margin-top: 10px;
        width: 100%;

        @media #{$media-tablet-horizontal} {
          padding: 0;
        }

        p {
          @include display-flex;
          width: calc(33.33% - 6px);
          justify-content: center;
          padding: 4px 2px;
          border: 0.795967px solid var(--text_color_primary);
          border-radius: 3.18387px;
          align-items: center;
          cursor: pointer;

          img {
            margin: 4px;

            @media #{$media-tablet-horizontal} {
              margin: 4px 2px 4px 0;
            }
          }

          span {
            font-weight: 500;
            font-size: $font_size_small;
            color: var(--text_color_primary);
          }
        }
      }

      &Range {
        width: 100%;
        padding: 16px 0 0;

        &Input {
          position: absolute;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          display: block;
          width: 100%;
          height: 3px;
          border-radius: 3px;
          background-color: transparent;
          outline: none;
          z-index: 99;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          &::-webkit-slider-runnable-track {
            -webkit-appearance: none;
            height: 3px;
            background-color: transparent;
          }

          &::-moz-track {
            -moz-appearance: none;
            height: 3px;
            background-color: transparent;
          }

          &::-ms-track {
            appearance: none;
            height: 3px;
            background-color: transparent;
          }

          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            height: 8px;
            width: 8px;
            background-color: var(--text_color_primary);
            pointer-events: auto;
            border: 1px solid var(--color_secondary) !important;
            cursor: pointer;
            margin-top: -3px;
            pointer-events: auto;
            border: none;
            transform: rotate(45deg);
          }

          &::-ms-thumb {
            position: absolute;
            appearance: none;
            height: 8px;
            width: 8px;
            background-color: var(--text_color_primary);
            border: 1px solid var(--color_secondary) !important;
            pointer-events: auto;
            cursor: pointer;
            margin-top: -3px;
            pointer-events: auto;
            border: none;
            transform: rotate(45deg);
          }

          &::-moz-range-thumb {
            position: absolute;
            -moz-appearance: none;
            height: 8px;
            width: 8px;
            background-color: var(--text_color_primary);
            border: 1px solid var(--color_secondary) !important;
            pointer-events: auto;
            cursor: pointer;
            margin-top: -3px;
            pointer-events: auto;
            border: none;
          }
        }

        &Background {
          position: absolute;
          z-index: 9;
          width: calc(100% - 4px);
          height: 4px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 4px;
          background-color: var(--background_color_tertiary);
        }

        &Knot {
          pointer-events: none;
          width: calc(100% - 4px);
          height: 6px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          position: absolute;
          @include display-flex;
          justify-content: space-between;
          z-index: 10;

          &Item {
            background: var(--background_color_tertiary);
            height: 6px;
            width: 6px;
            border-radius: 50%;
          }

          .backgroundWhite {
            background-color: var(--text_color_primary) !important;
          }
        }

        &Percent {
          position: absolute;
          color: var(--text_color_primary);
          font-size: $font_size_small;
          top: -10px;
        }
      }
    }

    &Info {
      width: calc(33.333% - 8px);

      @media #{$media-tablet-horizontal} {
        width: calc(30% - 6px);
      }
      @media screen and (min-width: 1161px) and (max-width: 1300px) {
        width: calc(32% - 8px);
      }
      &Mobile {
        display: none;

        @media only screen and (max-width: 769px) {
          // position: fixed;
          // bottom: 0;
          // left: 0;
          // right: 0;
          // z-index: 100;
          // background-color: #25292f;
          width: 100%;
          display: block;
          padding: 10px 0;
        }
      }

      &Period {
        @include display-flex;
        justify-content: space-between;
        align-items: end;
        @media screen and (min-width: 1161px) and (max-width: 1300px) {
          display: block;
          text-align: center;
        }

        &Detail {
          font-size: $font_size_small;
          color: var(--text_color_primary);

          @media #{$media-tablet-horizontal} {
            font-size: $font_size_small;
          }
        }

        &Time {
          font-size: $font_size_small;
          color: var(--text_color_primary);

          strong {
            font-size: $font_size_large !important;
            line-height: $font_size_large;
          }
        }
      }

      &Updown {
        margin-top: 12px;
        @include display-flex;
        justify-content: space-between;

        @media only screen and (max-width: 769px) {
          margin-top: 0;
        }

        p {
          position: relative;
          @include display-flex;
          justify-content: center;
          padding: 18px 8px 14px;
          width: calc(50% - 2px);
          border-radius: 3.60063px;
          cursor: pointer;
          user-select: none;

          .moneyPlayLeft,
          .moneyPlayRight {
            position: absolute;
            top: 1px;
            transform: translateY(-100%);
            color: var(--color_secondary);
            font-size: $font_size_normal;
            line-height: $font_size_normal;
            left: 50%;
            transform: translateX(-50%);
            font-weight: 600;
            padding: 2px 4px;
            border-radius: 2px;
          }

          // .moneyPlayLeft {
          //   left: 0;
          //   right: unset;
          // }

          // .moneyPlayRight {
          //   right: 0;
          //   left: unset;
          // }

          span {
            font-size: $font_size_large;
            text-transform: uppercase;
            color: var(--text_color_primary);
          }

          img {
            height: 25px;
            width: 25px;
            margin-left: 4px;
          }
          @media screen and (min-width: 1025px) and (max-width: 1279px) {
            span {
              font-size: 18px;
            }
            img {
              height: 18px;
              width: 18px;
            }
          }
        }
      }
    }

    &Parameter {
      width: calc(33.333% - 8px);

      @media #{$media-tablet-horizontal} {
        width: calc(30% - 6px);
      }
      @media screen and (min-width: 1025px) and (max-width: 1300px) {
        width: calc(30% - 8px);
      }
      &Value {
        @include display-flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;

        &Profit {
          font-weight: 400;
          font-size: $font_size_small;
          color: var(--text_color_primary);

          span {
            font-weight: 400;
            font-size: $font_size_x_medium;
            text-transform: uppercase;
            margin-left: 6px;
            color: var(--color_secondary);
          }
        }

        &Money {
          font-weight: 400;
          font-size: $font_size_x_large;
          text-transform: uppercase;
          color: var(--color_primary);
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &Line {
        &Title {
          margin: 8px 0;
          text-align: center;
          font-weight: 400;
          font-size: $font_size_small;
          color: var(--text_color_primary);
        }

        .lineRed {
          height: 4px;
          background-color: #ff715e;
          border-radius: 4px;
          @media only screen and (max-width: 450px) {
            display: none;
          }
        }

        .lineGreen {
          height: 4px;
          background-color: #36ffb5;
          border-radius: 4px;
          float: right;
          transition: 0.5s;
          @media only screen and (max-width: 450px) {
            display: none;
          }
        }

        &Number {
          p {
            font-weight: 400;
            font-size: $font_size_small;
            line-height: 14px;
          }
        }
      }
    }
  }
  &Result {
    padding: 0;

    @media #{$media-tablet-horizontal} {
      padding: 0;
    }

    &Head {
      width: 100%;
      @include display-flex;

      @media #{$media-tablet-horizontal} {
        display: none;
      }

      &Increase,
      &Decrease {
        padding: 8px;
        width: 50%;
        text-align: center;
        font-weight: 400;
        font-size: $font_size_normal;
        color: var(--text_color_primary);

        img {
          height: 18px;
          width: 14px;
          margin-left: 4px;
        }
      }
    }

    &Main {
      @include display-flex;
      // pointer-events: none !important;

      &V1,
      &V2 {
        width: 50%;
        padding: 8px 12px 0px 12px;
        border: 1px solid var(--border_color_primary);
        border-top: none;

        @media #{$media-tablet-horizontal} {
          border-top: 1px solid var(--border_color_primary);
        }

        @media only screen and (max-width: 769px) {
          border-top: 1px solid var(--border_color_primary);
          padding: 6px;
        }
      }

      &V1 {
        border-left: none;
        border-right: none;
      }

      &V2 {
        border-right: none;
      }

      &V1 {
        @media only screen and (max-width: 500px) {
          width: 42%;
          padding-right: 0;
        }

        .chartResult {
          @include display-flex;
          overflow-x: auto;
          padding: 0;
          padding-bottom: 4px;
          margin: auto;

          &Element {
            min-width: 102px;
            height: 102px;
            width: 102px;
            @include display-flex;
            flex-direction: column;
            flex-wrap: wrap;
            margin-right: 11px;
            align-content: start;

            &:last-child {
              margin-right: 0;
            }
          }

          &Circle {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin: 0 8px 5px 0;
          }

          &X {
            width: 11px;
            height: 11px;
            margin: 0 9px 6px 0;
          }

          &::-webkit-scrollbar {
            height: 4px;
          }

          &::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          &::-webkit-scrollbar-thumb {
            background: #888;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        }
      }

      &V2 {
        padding: 8px 8px 4px 8px;

        @media only screen and (max-width: 500px) {
          width: 58%;
          padding: 0 25px 0 0;
        }

        @media only screen and (max-width: 769px) {
          padding: 6px 5px 6px 6px;
        }

        .containResult {
          width: 100%;
          overflow-x: auto;

          &::-webkit-scrollbar {
            height: 4px;
          }

          &::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          &::-webkit-scrollbar-thumb {
            background: #888;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        }

        .chartResult {
          @include display-flex;
          flex-direction: column;
          flex-wrap: wrap;
          min-height: 106px;
          height: 106px;
          width: fit-content;
          padding: 0;

          &Element {
            background: #0f152f;
            min-width: 102px;
            height: 102px;
            width: 102px;
            @include display-flex;
            flex-direction: column;
            flex-wrap: wrap;
            margin-left: 11px;
          }

          &Circle {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin: 0 5px 5px 0;
          }

          &X {
            width: 11px;
            height: 11px;
            margin: 0 5px 6px 1px;
          }
          &ImageCircle {
            width: 17px;
            height: 17px;
            position: relative;

            .ic_lineVertical_TANG,
            .ic_lineVertical_GIAM,
            .ic_lineVertical_GIAM_last,
            .ic_lineVertical_TANG_last {
              position: absolute;
              width: 12px;
              height: 17px;
            }
            .ic_lineHorizontal_TANG,
            .ic_lineHorizontal_GIAM,
            .ic_lineHorizontal_TANG_last,
            .ic_lineHorizontal_GIAM_last {
              position: absolute;
              width: 17px;
              height: 12px;
            }
            .ic_linePerpendicular_TANG,
            .ic_linePerpendicular_GIAM {
              position: absolute;
              width: 17px;
              height: 17px;
            }
            .ic_lineNoLine,
            .ic_lineNoLineTANG,
            .ic_lineNoLineGIAM {
              position: absolute;
              width: 17px;
              height: 12px;
            }
          }

          .lineVerticalTANG,
          .lineVerticalGIAM {
            position: relative;
            &::before {
              content: '';
              position: absolute;
              left: 50%;
              top: 0;
              height: 100%;
              width: 1px;
              transform: translateX(-50%);
            }
          }

          .lineHorizontalTANG,
          .lineHorizontalGIAM {
            position: relative;
            &::before {
              content: '';
              position: absolute;
              top: 50%;
              right: 0;
              height: 1px;
              width: 100%;
              transform: translateY(-50%);
            }
          }

          .linePerpendicularTANG,
          .linePerpendicularGIAM {
            position: relative;
            &::before {
              content: '';
              position: absolute;
              left: 50%;
              top: 0;
              height: calc(50% + 0.4px);
              width: 1px;
              transform: translateX(-50%);
            }
            &::after {
              content: '';
              position: absolute;
              top: 50%;
              right: 0;
              height: 1px;
              width: calc(50% + 0.4px);
              transform: translateY(-50%);
            }
          }

          .lineVerticalTANG,
          .lineHorizontalTANG,
          .linePerpendicularTANG {
            &::before,
            &::after {
              background-color: var(--color_primary);
            }
          }
          .lineVerticalGIAM,
          .lineHorizontalGIAM,
          .linePerpendicularGIAM {
            &::before,
            &::after {
              background-color: var(--color_warn_primary);
            }
          }
        }
      }
    }
  }

  &TabMobile {
    display: none;

    @media #{$media-tablet-horizontal} {
      @include display-flex;
      align-items: center;
    }

    &Tab {
      max-width: calc(100% - 100px);
      padding: 8px 16px;
      @include display-flex;
      align-items: center;
      cursor: pointer;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;

      @media only screen and (max-width: 540px) {
        padding: 6px;
      }

      &Scroll {
        @include display-flex;
        @media #{$media-tablet-vertical} {
          padding-bottom: 5px;
        }
      }

      .activeItem {
        border-bottom: 2px solid var(--color_secondary) !important;
        color: var(--color_secondary) !important;
      }

      .item {
        white-space: nowrap;
        font-weight: 400;
        font-size: $font_size_normal;
        color: var(--text_color_primary);
        padding: 8px 6px 2px;
        @media only screen and (max-width: 540px) {
          font-size: $font_size_small;
          padding: 8px 6px 2px;
        }
      }
    }

    &Result {
      @include display-flex;
      margin-left: 8px;

      p {
        padding: 4px;
        background-color: var(--background_color_secondary);
        margin: 0 2px;

        img {
          width: 14px;
          height: 14px;
          margin-right: 4px;
        }

        span {
          font-weight: 400;
          font-size: $font_size_normal;
          text-align: right;
          color: var(--text_color_primary);
        }
      }
    }
  }
}

@media only screen and (max-width: 769px) {
  .chart {
    &Play {
      flex-direction: column;

      &Parameter {
        width: 100%;
        display: none;

        &Value {
          flex-direction: row;
          justify-content: space-between;
          padding-bottom: 3px;
          display: none;

          &Profit {
            font-size: $font_size_small;
            font-weight: 400;

            span {
              font-size: $font_size_normal;
              font-weight: 400;
            }
          }

          &Money {
            font-size: $font_size_medium;
            font-weight: 400;
            line-height: 18px;
          }
        }

        &Line {
          &Title {
            display: none;
          }
        }
      }

      &Info {
        display: none;

        &Mobile {
          display: block;
        }

        &Updown {
          margin-top: 0px;
          @include display-flex;
          gap: 8px;

          .paddingMoney2 {
            padding: 15px 0 11px !important;
          }

          p {
            position: relative;
            @include display-flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            padding: 2px 6px;
            width: calc(100% / 3);
            border-radius: 3.60063px;
            cursor: pointer;
            user-select: none;

            span {
              font-weight: 600;
              font-size: $font_size_x_medium;
              text-transform: uppercase;
              color: var(--text_color_primary);
            }

            img {
              height: 20px;
              width: 25px;
              margin-left: 4px;
            }

            &.countDownOnPhone {
              display: block;
              border: 0.79597px solid var(--color_secondary);
              text-align: center;

              span {
                &:nth-child(1) {
                  display: block;
                  font-size: $font_size_small;
                  line-height: $font_size_small;
                  font-weight: 400;
                  text-transform: none;
                }
                strong {
                  line-height: $font_size_large;
                }
              }
            }
          }
        }
      }

      &Action {
        width: 100%;

        &Range {
          width: 85%;
          @media screen and (max-width: 769px) {
            display: none;
          }
        }

        &Wallet {
          flex-direction: row;
          align-items: end;
          justify-content: space-between;
          @media screen and (min-width: 451px) {
            width: 50%;
          }

          .chartPlayParameterValueProfit {
            font-size: $font_size_normal;
            line-height: $font_size_normal;
            display: block;
          }
          .chartPlayParameterValueMoney {
            font-size: $font_size_x_medium;
            color: var(--color_primary);
            display: block;
          }
        }

        &PeriodDetail {
          color: var(--text_color_primary) !important;
          display: none;
        }
        .availability {
          display: none;
        }

        &Amount {
          width: 70%;
          &Value {
            width: 100%;
            gap: 12px;

            input {
              min-height: 40px;
              padding-left: 16px;
              width: 100%;
            }

            img {
              top: 12px;
            }

            span {
              min-height: 40px;
              min-width: 60px;
              font-size: $font_size_x_medium;
            }

            .dollar {
              font-size: $font_size_x_medium;
              top: 9px;
              left: 4px;
            }
          }
        }

        &Button {
          min-width: 200px;
          width: 70%;
          order: 1;
          margin-top: 0px;
          margin-bottom: 0px;
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .chart {
    &Play {
      padding: 6px 10px 0px;

      &Desktop {
        display: none;
      }

      &Mobile {
        display: block;
      }

      &Action {
        &Wallet {
          display: flex;
          gap: 30px;
          align-items: end;

          .chartPlayParameterValueProfit {
            font-size: $font_size_normal;
            line-height: $font_size_normal;
            span {
              font-size: $font_size_x_medium;
              line-height: $font_size_x_medium;
            }
          }
          .chartPlayParameterValueMoney {
            font-size: $font_size_x_medium;
            line-height: $font_size_x_medium;
            color: var(--color_primary);
          }
        }

        &Amount {
          width: 100%;
          &Value {
            width: 100%;
            &Input {
              width: 100% !important;
              input {
                width: 100%;
              }
            }

            input {
              font-size: $font_size_x_medium;
              font-weight: 400;
              padding-left: 15px;
            }
          }
        }

        &Button {
          gap: 6px;
          width: 100%;

          p {
            min-width: 80px;
            height: 24px;
            gap: 4px;

            img {
              margin: 0;
            }

            span {
              font-size: $font_size_small;
              font-weight: 400;
            }
          }
        }
      }

      &Info {
        &Mobile {
          display: block;
        }

        &Updown {
          margin-top: 0px;
          @include display-flex;
          gap: 8px;

          p {
            @include display-flex;
            justify-content: center;
            align-items: center;
            padding: 2px 6px;
            width: calc(100% / 3);
            border-radius: 3.60063px;
            cursor: pointer;
            user-select: none;

            span {
              font-size: $font_size_x_medium;
              line-height: 22px;
              text-transform: uppercase;
              color: var(--text_color_primary);
            }

            img {
              height: 20px;
              width: 25px;
              margin-left: 4px;
            }

            &.countDownOnPhone {
              display: block;
              border: 0.79597px solid var(--color_secondary);
              text-align: center;

              span {
                &:nth-child(1) {
                  display: block;
                  font-size: $font_size_small;
                  font-weight: 400;
                  text-transform: none;
                }
              }
            }
          }
        }
      }

      &Parameter {
        margin-top: 12px;
        display: none;

        &Value {
          &Profit {
            font-size: $font_size_small;

            span {
              font-size: $font_size_normal;
              margin-left: 3px;
            }
          }

          &Money {
            font-size: $font_size_x_medium;
          }
        }

        &Line {
          &Number {
            margin-top: 4px;
            font-size: $font_size_normal;
          }
        }
      }
    }
  }
}
