@import '../../../assets/styles/helpers/variables';

.basic-alert-popup {
  position: relative;
  .text-highlight {
    font-size: 18px;
  }
  .sub-title {
    font-size: 14px;
    line-height: 1.25;
    text-align: center;
    margin-top: 5px;
  }
  .swal-button--cancel {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 27px !important;
    height: 27px !important;
    min-width: 27px !important;
    min-height: 27px !important;
    padding: 0 !important;
    border-radius: 9px !important;
    background-color: var(--bg_color_btn_close) !important;
    border: none !important;
    &:before, &:after {
      content: '';
      width: 45%;
      height: 1px;
      background: var(--text_color_primary);
      position: absolute;
      left: 50%;
      top: 50%;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    &:focus, &:active {
      border: none !important;
      box-shadow: none !important;
    }
  }
  .swal-footer {
    justify-content: center;
    gap: 0 !important;
    .swal-button-container {
      width: auto !important;
      margin: 0 !important;
    }
    .swal-button--confirm {
      width: 266px !important;
    }
  }
  .swal-button-container {
    position: static;
  }

  &.swal-modal {
    @media #{$media-tablet-vertical} {
      width: calc(100% - 40px) !important;
    }
  }
}