@import '../../../../../../assets/styles/helpers/variables';
@import '../../../styles/theme.scss';

.headerGameBoV3 {
  min-height: 58px;
  height: 58px;
  padding: 12px 8px;
  @include display-flex;
  justify-content: space-between;
  background-color: var(--background_color_primary);
  border-bottom: 1px solid var(--border_color_primary);

  @media #{$media-tablet-horizontal} {
    padding: 12px;
  }

  @media (min-width: 1161px) and (max-width: 1280px) {
    padding: 12px 10px;
  }

  .hiddenPhone {
    @media #{$media-tablet-horizontal} {
      display: none;
    }
  }

  .hiddenDesktop {
    @media only screen and (min-width: 1161px) {
      display: none;
    }
  }

  .widthMobile100 {
    @media #{$media-tablet-horizontal} {
      width: 100%;
    }
  }

  @media #{$media-tablet-horizontal} {
    flex-direction: row-reverse;
  }

  .header {

    &Left,
    &Right {
      @include display-flex;
      align-items: center;
    }

    &Info {
      @include display-flex;
      align-items: center;

      @media #{$media-tablet-horizontal} {
        flex-direction: row-reverse;
        // gap: 10px
      }

      &Options {
        height: 22px;
        width: 22px;
        display: none;
        margin-right: 20px;

        @media #{$media-tablet-horizontal} {
          margin-left: 10px;
          margin-right: 0px;
          display: inline-block;
        }
      }

      &Logo {
        width: 92px;
        margin-right: 4px;
      }

      &User {
        @include display-flex;
        align-items: center;
        margin-left: 8px;

        @media only screen and (max-width: 1367px) {
          margin-left: 8px;
        }

        @media #{$media-tablet-horizontal} {
          margin-left: 14px;
          margin-right: 12px;
          gap: 10px;
          flex-direction: row-reverse;
        }

        @media #{$media-tablet-vertical} {
          margin-left: 0;
          margin-right: 4px;
          order: 1;
        }

        &Avatar {
          height: 34px;
          width: 34px;
          border-radius: 50%;
          overflow: hidden;

          @media only screen and (max-width: 376px) {
            display: none;
          }
        }

        &Detail {
          @include display-flex;
          flex-direction: column;
          align-items: center;
          margin-left: 4px;
          position: relative;

          &Choose {
            display: flex;
            align-items: center;
            padding: 4px 4px 4px 8px;
            background-color: rgb(56, 60, 66);
            border-radius: 4px;

            img {
              padding: 3px;
              height: 18px;
              width: 18px;
              background-color: var(--color_disable);
              border-radius: 50%;
              margin-left: 6px;
              cursor: pointer;
            }
          }

          &Name {
            font-weight: 400;
            font-size: $font_size_small;
            color: var(--text_color_primary);
          }

          &Money {
            font-weight: 400;
            font-size: $font_size_normal;
            color: var(--color_secondary);
          }

          .dropdownWallet {
            position: absolute;
            bottom: -12px;
            transform: translateY(100%);
            padding: 4px 0px;
            background-color: rgb(56, 60, 66);
            border: 1px solid var(--background_color_tertiary);
            border-radius: 8px;
            z-index: 10;
            min-width: 220px;

            &Item {
              color: var(--background_color_chatbox_cskh) !important;
              border-bottom: 1px solid var(--background_color_tertiary);
              padding: 8px 16px;
              display: flex;
              cursor: pointer;

              &:hover {
                background-color: var(--background_color_tertiary);
              }

              &:last-child {
                border-bottom: none;
              }

              .yellowBackground {
                background-color: var(--color_secondary) !important;
              }

              &Circle {
                border-radius: 50%;
                margin-right: 8px;
                margin-top: 3px;
                height: 16px;
                width: 16px;
                border: 3px solid var(--background_color_chatbox_cskh);
                background-color: var(--background_color_chatbox_cskh);
              }

              &Name {
                font-weight: 400;
                font-size: $font_size_small;
              }

              &Money {
                font-weight: 400;
                font-size: $font_size_normal;
                color: var(--white_color);
                margin-top: 4px;
                font-weight: 600px;
              }
            }
          }

          &Money,
          &Name {
            white-space: nowrap;
          }
        }
      }
    }

    &Nav {
      margin-left: 16px;

      @media only screen and (max-width: 1367px) {
        margin-left: 12px;
      }

      @media #{$media-tablet-horizontal} {
        display: none;
      }

      // @media #{$media-tablet-vertical} {
      //   display: none;
      // }
    }

    &Options {
      @include display-flex;
      align-items: center;

      .logo-mobile {
        display: none;

        @media #{$media-tablet-horizontal} {
          display: block;
        }
      }

      &Recharge {
        cursor: pointer;
        padding: 6px 8px;
        background-color: var(--color_secondary);
        font-style: normal;
        font-size: $font_size_normal;
        text-align: center;
        color: var(--text_color_tertiary);
        border: 1px solid var(--color_secondary);
        border-radius: 4px;
        font-weight: 600;

        @media #{$media-tablet-horizontal} {
          padding: 4px 6px;
        }

        @media only screen and (max-width: 350px) {
          display: none;
        }
      }

      &Notification,
      &Volume {
        margin-left: 20px;
        height: 20px;
        width: 20px;
        cursor: pointer;

        @media only screen and (max-width: 1367px) {
          margin-left: 12px;
        }

        @media #{$media-tablet-horizontal} {
          margin-left: 10px;
        }

        &Setting {
          position: absolute;
          bottom: -12px;
          right: 0;
          transform: translateY(100%);
          min-width: fit-content;
          padding: 4px;
          background-color: var(--text_color_tertiary);

          p {
            white-space: nowrap;
            cursor: pointer;
            padding: 8px 6px;
            font-size: $font_size_medium;
            border-bottom: 1px solid var(--text_color_placeholder);

            &:last-child {
              border: none;
            }

            &:hover {
              background-color: var(--background_color_tertiary);
            }
          }
        }
      }

      &Volume {
        @media #{$media-tablet-horizontal} {
          display: none;
        }
      }

      &DarkMode {
        margin-left: 20px;
        position: relative;
        background: #d9d9d9;
        border-radius: 6.96552px;
        width: 28px;
        height: 14px;
        cursor: pointer;
        background-color: #d9d9d9;

        @media only screen and (max-width: 1367px) {
          margin-left: 12px;
        }

        @media #{$media-tablet-horizontal} {
          margin-left: 10px;
        }

        @media #{$media-tablet-horizontal} {
          display: none;
        }

        span {
          position: absolute;
          top: 0;
          left: 0;
          width: 14px;
          height: 14px;
          border-radius: 14px;
          background-color: var(--background_color_tertiary);
          transition: 0.5s;
          @include display-flex;
          justify-content: center;
          align-items: center;

          img {
            width: 14px;
            height: 14px;
            border-radius: 14px;
            overflow: hidden;
          }
        }

        input {
          appearance: none;
        }

        input:checked~span {
          left: 14px;
        }
      }

      &Logout {
        cursor: pointer;
        margin-left: 20px;
        font-size: $font_size_normal;
        color: var(--text_color_primary);

        @media only screen and (max-width: 1367px) {
          margin-left: 12px;
        }

        @media #{$media-tablet-horizontal} {
          margin-left: 10px;
        }
      }

      .hiddenLogout {
        @media #{$media-tablet-horizontal} {
          display: none;
          margin-left: 20px;
        }
      }

      .notification-header {
        position: relative;

        .header-notify-active {
          position: absolute;
          right: 0;
          top: 0;
          width: 12px;
          height: 12px;
          background: var(--bg_button);
          border: 0.4px solid var(--color_secondary);
          border-radius: 50%;
        }

        @media #{$media-tablet-horizontal} {
          display: none;
        }
      }
    }
  }

  .backgroundOrange {
    background-color: var(--color_secondary) !important;
  }

  .btn_button {
    background: linear-gradient(180deg, #feb914 0%, #ffca4d 100%) !important;
    color: #000 !important;
    font-weight: 600;
  }
}

@media all and (max-width: 1153px) {
  .responsis {
    position: absolute;
    right: 225px;
  }
}

@media #{$media-tablet-horizontal} {
  .responsis {
    display: none;
  }
}

.responsis2 {
  display: none;
  margin-right: 8px;
}

@media #{$media-tablet-horizontal} {
  .responsis2 {
    display: block;
  }
}

@media #{$media-ipad} {
  .responsis2 {
    display: none;
  }
}