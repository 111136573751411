@import '../../../../../../assets/styles/helpers/variables';
@import '../../../styles/theme.scss';

.resultGame {
  .isDisplayOnMobile {
    @media #{$media-tablet-horizontal} {
      display: none;
    }
  }

  .left1 {
    width: 37%;
    text-align: left;
  }

  .left2 {
    width: 36%;
    text-align: left;
  }

  .right {
    width: 27%;
    text-align: right;
  }

  .leftAQuarter1 {
    width: 25%;
    text-align: left;
  }

  .leftAQuarter2 {
    width: 27%;
    text-align: left;

    @media #{$media-tablet-horizontal} {
      display: flex;
      align-items: center;
      margin-top: 0px;
    }

    @media only screen and (max-width: 560px) {
      display: block;
      margin-top: 2px;
    }
  }

  .flexCoin {
    margin-top: 3px;

    @media #{$media-tablet-horizontal} {
      margin-top: 0px;
    }

    @media only screen and (max-width: 560px) {
      margin-top: 3px;
    }
  }

  .leftAQuarter3 {
    width: 18%;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .rightAQuarter {
    width: 30%;
    text-align: right;
  }

  .leftResult1 {
    width: 50%;
    text-align: left;
  }

  .rightResult {
    width: 18%;
    text-align: right;
  }

  .leftResult2 {
    width: 32%;
    text-align: left;
  }

  .left1,
  .right,
  .leftAQuarter {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .title {
    cursor: pointer;
    font-weight: 500;
    font-size: $font_size_normal;
    color: var(--text_color_primary);

    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }

  .activeTab {
    font-weight: 500 !important;
    color: var(--color_secondary) !important;
  }

  .titleMyTransaction {
    cursor: pointer;
    font-weight: 500;
    font-size: $font_size_normal;
    color: var(--text_color_primary);
  }

  .typeTimePlay {
    font-size: $font_size_small;
  }

  &List {
    padding: 0 20px;

    &NoData {
      color: var(--text_color_primary);
      padding: 20px 16px;
      font-size: $font_size_small;
      text-align: center;
    }

    .borderItem {
      padding-bottom: 4px;
      border-bottom: 1px solid var(--border_color_primary);
    }

    &Head {
      @include display-flex;
      margin: 6px 0;

      p {
        font-weight: 500;
        font-size: $font_size_small;
        color: var(--text_color_primary);
      }
    }

    &Item {
      @include display-flex;
      margin-bottom: 5px;

      .day,
      .play {
        font-size: $font_size_small;
      }

      p {
        font-weight: 400;
        font-size: $font_size_small;
        color: var(--text_color_primary);

        @media only screen and (max-width: 1024px) {
          font-size: $font_size_small;
        }
      }

      span {
        font-weight: 400;
        font-size: $font_size_small;
        color: var(--color_secondary);
        padding: 1.5px 4px;
        background-color: var(--color_secondary_darker);
        border-radius: 1.3px;

        @media only screen and (max-width: 1024px) {
          font-size: $font_size_small;
        }
      }
    }
  }

  &Transaction {
    .title {
      padding: 20px 20px 12px;
    }
  }

  &Record {
    &Head {
      @media only screen and (max-width: 1024px) {
        display: none;
      }

      border-bottom: 1px solid var(--border_color_primary);
      border-top: 1px solid var(--border_color_primary);
      padding: 8px 20px;
      @include display-flex;
      justify-content: space-between;
    }

    .totalProfit {
      @include display-flex;
      justify-content: space-between;
      padding: 8px 0;
      color: var(--color_secondary);
      font-size: $font_size_normal;
      font-weight: 600;
    }
  }

  &Pagination {
    margin-top: 20px !important;
    padding-bottom: 8px !important;
    display: flex;
    color: #ffffff;
    justify-content: center;

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-disabled {
      .ant-pagination-item-link {
        color: var(--text_color_primary) !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .ant-pagination-item {
      color: var(--text_color_primary);
      border-radius: 2px;
      border: 1px solid var(--text_color_tertiary) !important;
      border-color: var(--text_color_tertiary) !important;
      margin: 0 2px !important;
    }

    @media #{$media-tablet-vertical} {
      justify-content: center !important;
      padding-right: 16px;
    }

    .ant-pagination-item-ellipsis {
      color: #ffffff !important;
    }

    li {
      background: transparent;
      border: 1px solid rgba(255, 255, 255, 0.25);
    }

    .ant-pagination-item-active {
      background: var(--color_secondary);
      color: var(--text_color_primary);
      border: none;
    }
  }
}