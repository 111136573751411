#Modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;

  .modalBasicCustom {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }

  .modalBasicCustom-container {
    position: relative;
    border-radius: 12px;
    min-width: 520px;
    max-width: 700px;
    padding: 30px;
    border-radius: 11.979px;
    background: var(--background_color_popup);
    box-shadow: 0px 0px 23.9573px 0px rgba(0, 0, 0, 0.8);

    &::before {
      position: absolute;
      content: '';
      height: 5px;
      top: 0;
      width: 70%;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 0 0 3px 3px;
      background-color: var(--color_secondary);
    }

    .closeButton {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .title {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      width: 80%;
      margin: auto;
      text-align: center;
      color: var(--color_secondary);
      margin-bottom: 30px;
    }

    @media screen and (max-width: 768px) {
      width: 90%;
      min-width: unset;

      .title {
        font-size: 20px;
      }

      padding: 30px;
    }

    @media screen and (max-width: 540px) {
      width: 95%;
      padding: 12px;
    }
  }
}