@import '../../../../../../assets/styles/helpers/variables';
@import '../../../styles/theme.scss';

.show_right_parameter {
  @media only screen and (max-width: 600px) {
    border: none !important;
    padding: 0 !important;
    order: 1;
    justify-content: space-between !important;
  }
}

.parameter {
  position: relative;
  @include display-flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--border_color_primary);
  padding: 4px 12px;
  border-top: none;

  @media (min-width: 1161px) and (max-width: 1280px) {
    padding: 4px 6px;
  }

  @media only screen and (max-width: 1160px) {
    padding: 4px 12px 8px;
  }

  @media only screen and (max-width: 655px) {
    flex-wrap: nowrap;
  }

  .show_open_close {
    position: relative;
    display: none;

    @media only screen and (max-width: 600px) {
      display: flex;
      align-items: center;
      order: 2;
    }
  }

  .display_none_mobile {
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  &Left {
    @include display-flex;
    align-items: center;

    @media only screen and (max-width: 600px) {
      width: 100%;
      justify-content: space-between;
    }
  }

  &Type {
    @include display-flex;
    align-items: center;

    &Coin {
      cursor: pointer;
      text-align: center;
      position: relative;

      &Name {
        font-weight: 700;
        font-size: $font_size_large;
        color: var(--text_color_primary);
        text-transform: uppercase;

        @media (min-width: 1161px) and (max-width: 1280px) {
          font-size: 18px;
        }

        @media only screen and (max-width: 1160px) {
          font-size: 18px;
        }

        @media only screen and (max-width: 900px) {
          font-size: $font_size_x_medium;
        }
      }

      &Source {
        img {
          height: 9px;
          width: 9px;
          margin-right: 3px;
        }

        span {
          font-size: $font_size_small;
          color: var(--text_color_secondary);
          text-decoration: underline;
        }
      }

      &Select {
        transition: ease-in-out 0.5s;
        position: absolute;
        z-index: 100;
        background-color: var(--text_color_tertiary);
        bottom: -10px;
        left: -20px;
        right: -20px;
        transform: translateY(100%);
        padding: 4px;
        max-height: 200px;
        overflow-y: auto;
        min-width: 190px;

        p {
          cursor: pointer;
          padding: 8px 6px;
          font-size: $font_size_medium;
          color: var(--text_color_primary);
          display: block;

          &:hover {
            background-color: var(--background_color_tertiary);
          }
        }
      }
    }

    &Line {
      margin: 0 20px;
      width: 1px;
      height: 42px;
      background-color: var(--border_color_primary);

      @media only screen and (max-width: 1280px) {
        margin: 0 12px;
      }
    }

    &Price {
      margin-right: 18px;

      &Main {
        font-weight: 400;
        font-size: $font_size_x_medium;
        color: var(--color_primary);

        @media (min-width: 912px) and (max-width: 1280px) {
          font-size: 19px;
        }

        @media only screen and (max-width: 320px) {
          font-size: 24px !important;
          line-height: 1.25 !important;
        }

        @media only screen and (max-width: 911px) {
          font-size: $font_size_x_large !important;
          line-height: 1.25 !important;
        }
      }

      &Sub {
        font-weight: 400;
        font-size: $font_size_small;
        color: var(--text_color_primary);
      }

      &Percent {
        display: none;
        font-weight: 600;
        font-size: $font_size_small;
        color: var(--color_primary);
      }
    }

    &Volatility {
      margin-right: 18px;

      &Title {
        font-weight: 400;
        font-size: $font_size_small;
        color: var(--text_color_secondary);
      }

      &Value {
        margin-top: 3px;
        font-weight: 400;
        font-size: $font_size_small;
        color: var(--color_primary);
        white-space: nowrap;
      }
    }
  }

  &Price {
    @include display-flex;
    align-items: center;

    &Item {
      margin-right: 16px;

      &Title {
        font-size: $font_size_small;
        color: var(--text_color_secondary);
      }

      &Value {
        margin-top: 3px;
        font-weight: 400;
        font-size: $font_size_small;
        color: var(--text_color_primary);
        white-space: nowrap;
      }
    }
  }

  &Right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    @media only screen and (max-width: 1279px) {
      flex-direction: column;
    }

    @media #{$media-tablet-horizontal} {
      flex-direction: row;
    }

    @media only screen and (max-width: 912px) {
      flex-direction: column;
    }

    @media only screen and (max-width: 700px) {
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-around;
    }

    @media only screen and (max-width: 850px) {
      flex-direction: column;

    }

    @media only screen and (max-width: 600px) {
      width: calc(100% - 21px);
      flex-direction: row;
      justify-content: space-between;
    }

    &Time {
      display: flex;
      align-items: end;

      @media only screen and (max-width: 1025px) {
        display: flex;
        align-items: center;
      }
    }

    &Logo {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    @include display-flex;
    align-items: center;

    &Title {
      font-size: $font_size_small;
      color: var(--text_color_primary);

      @media only screen and (max-width: 769px) {
        font-size: $font_size_small;
      }

      @media screen and (min-width: 900px) and (max-width: 1300px) {
        display: none;
      }
    }

    &TypePlay {
      cursor: pointer;
      font-weight: 500;
      font-size: $font_size_normal;
      line-height: $font_size_normal;
      color: var(--text_color_primary);
      margin-left: 10px;
    }

    .typeActive {
      color: var(--color_secondary);
    }

    &TypeChart {
      height: 20px;
      width: 20px;
      cursor: pointer;
      margin: 0 12px 0 20px;
    }

    &MA {
      margin-left: 8px;
      margin-right: 8px;
      padding: 4px 10px;
      border: 2px solid var(--border_color_primary);
      border-radius: 2px;
      min-width: 64px;

      img {
        height: 14px;
        width: 14px;
        margin-right: 6px;
      }

      span {
        font-weight: 600;
        font-size: $font_size_normal;
        text-align: center;
        color: var(--text_color_secondary);
      }
    }
  }
}

.instruct,
.instruct2 {
  margin-right: 10px;
  font-size: $font_size_small;
  line-height: $font_size_small ;
  text-align: center;
  white-space: nowrap;
}


.instruct:hover,
.instruct2:hover {
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (max-width: 500px) {
  .parameterPrice {
    flex-wrap: wrap;
    max-width: 200px;
  }
}

@media only screen and (max-width: 1023px) {
  .parameter {
    &Left {
      .parameterType {
        margin-right: 15px;

        .parameterTypePrice {
          margin-right: 0;
          margin-top: 9px;

          &Percent {
            display: inline-block;
          }
        }

        &Volatility {
          display: none;
        }
      }

      .parameterPrice {
        // flex-wrap: wrap;
        // max-width: 300px;

        &Item {
          width: 50%;
          margin-right: 0;
          padding: 8px;
        }
      }
    }
  }

  @media only screen and (max-width: 850px) {
    .parameter {
      flex-wrap: wrap;

      &Type {
        flex-direction: column;
        align-items: flex-start;

        &Line {
          display: none;
        }
      }

      &Price {
        flex-wrap: wrap;
        max-width: 300px;
      }

      &Left {
        margin-bottom: 8px;
      }
    }
  }
}

@media all and (max-width: 912px) {
  .instruct {
    display: none;
  }
}

@media all and (min-width: 913px) {
  .instruct2 {
    display: none;
  }
}