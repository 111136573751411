@import '../../../../../../assets/styles/helpers/variables';
@import '../../../styles/theme.scss';

.navMobileGameBoV3 {
  .navMobileLayout {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 799;
  }

  .closeButton {
    top: -20px;
    right: -10px;
  }

  .navMobile {
    position: fixed;
    z-index: 800;
    background-color: var(--background_color_menu);
    padding: 28px 20px;
    padding-bottom: 12px;
    right: 0;
    top: 12px;
    border-radius: 38px 0 0 38px;
    min-width: 320px;
    max-height: calc(100vh - 24px);
    overflow-y: auto;

    &Header {
      &User {
        @include display-flex;
        align-items: center;

        img {
          height: 60px;
          width: 60px;
          border-radius: 50%;
          margin-right: 10px;
        }

        &Info {
          p {
            font-weight: 400;
            font-size: $font_size_lg;
          }

          span {
            font-weight: 400;
            font-size: $font_size_x_medium;
            color: var(--color_secondary);
          }
        }
      }

      &Action {
        @include display-flex;
        align-items: center;
        margin-top: 8px;

        &Volume {
          height: 20px;
          width: 20px;
          margin-left: 70px;
        }

        &CSKH {
          height: 18px;
          width: 18px;
          margin: 0 14px 0 24px;
        }

        &DarkMode {
          margin-left: 20px;
          position: relative;
          background: #d9d9d9;
          border-radius: 6.96552px;
          width: 28px;
          height: 14px;
          cursor: pointer;
          background-color: #d9d9d9;

          @media #{$media-tablet-horizontal} {
            margin-left: 10px;
          }

          span {
            position: absolute;
            top: 0;
            left: 0;
            width: 14px;
            height: 14px;
            border-radius: 14px;
            background-color: var(--background_color_tertiary);
            transition: 0.5s;
            @include display-flex;
            justify-content: center;
            align-items: center;

            img {
              width: 14px;
              height: 14px;
              border-radius: 14px;
              overflow: hidden;
            }
          }

          input {
            appearance: none;
          }

          input:checked~span {
            left: 14px;
          }
        }
      }
    }

    &Body {
      &Item {
        padding: 14px 0;
        border-bottom: 1px solid var(--background_color_tertiary);
        cursor: pointer;

        &:last-child {
          border-bottom: none;
        }

        &Parent {
          @include display-flex;
          align-items: center;

          &Image {
            height: 20px;
            width: 20px;
            margin-right: 8px;
          }

          &Name {
            &.isActive {
              color: var(--color_secondary);
            }

            font-weight: 400;
            font-size: $font_size_lg;
          }
        }

        &Children {
          @include display-flex;
          align-items: center;
          margin: 8px 0 0 20px;
          padding: 4px 0;

          &Image {
            height: 16px;
            width: 16px;
            margin-right: 8px;
          }

          &Name {
            font-weight: 400;
            font-size: $font_size_lg;
          }
        }
      }
    }
  }
}