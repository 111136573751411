@import '../../../../../../assets/styles/helpers/variables';
@import '../../../styles/theme.scss';

.header-notify {
  cursor: pointer;
  display: flex;
  padding: 7px;
  border: 1px solid var(--background_color_secondary);
  border-radius: 12px;

  .lineCenter {
    width: 1px;
    height: 42px;
    border: 1px solid var(--border_color_primary);
  }
  .customBorderLine {
    border-bottom: 1px solid var(--border_color_primary) !important;
    padding-bottom: 18px !important;
  }

  &-active {
    position: absolute;
    right: 0;
    top: 0;
    width: 12px;
    height: 12px;
    background: var(--color_secondary);
    border: 0.4px solid #f1a57b;
    border-radius: 50%;
  }

  &-icon {
    width: 20px;
    height: 20px;
  }

  &-text {
    font-size: $font_size_small;
    color: #b1bedd;
    font-weight: 700;
    margin-left: 4px;
  }

  &-mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: none;
    background: var(--background_color_secondary);
    padding: 20px;

    @media #{$media-tablet-horizontal} {
      display: flex;
    }

    &-icon {
      width: 24px;
      height: 24px;
      margin-right: 18px;
    }

    &-title {
      font-weight: 700;
      font-size: $font_size_normal;
      line-height: 24px;
      text-transform: uppercase;
      color: #fdfefd;
      margin-bottom: 0;
    }
  }

  &-container {
    position: absolute;
    z-index: 101;
    top: 43px;
    left: -400px;
    //right: -240px;
    background: var(--background_color_chatbox);
    border-radius: 12px;
    transition: 0.3s;
    padding: 18px 16px 0;
    overflow-y: scroll;
    max-height: 516px;
    min-width: 430px;

    @media #{$media-tablet-horizontal} {
      position: fixed;
      transition: none;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      max-height: 100vh;
      border-radius: 0;
      padding: 80px 16px 0;
      min-width: unset;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &-title {
      font-weight: 400;
      font-size: $font_size_x_medium;
      line-height: 22px;
      // color: var(--color_secondary);
      margin-right: 9px;
      cursor: pointer;
      width: 49%;
      display: flex;
      justify-content: center;
    }

    &-number {
      min-width: 22px;
      line-height: 22px;
      font-weight: 700;
      font-size: $font_size_normal;
      color: var(--background_color_menu);
      padding: 0 5px;
      background: var(--bg_button);
      border-radius: 8px;
      text-align: center;
    }

    &-event {
      font-weight: 400;
      font-size: 20px;
      line-height: 22px;
      color: var(--text_color_primary);
      margin-right: 9px;
      cursor: pointer;
      width: 49%;
      display: flex;
      justify-content: center;
    }

    &-content {
      padding-bottom: 15px;
    }

    .watched {
      background: var(--background_color_secondary);
    }

    &-element {
      display: block;
      padding: 16px 12px;
      margin-bottom: 4px;
      border-radius: 4px;
      background: var(--text_color_placeholder);
      text-decoration: none;

      &-title {
        margin-bottom: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-weight: 400;
        font-size: $font_size_medium;
        line-height: 22px;
        color: var(--color_secondary);
      }

      &-circle {
        height: 9px;
        min-width: 9px;
        border-radius: 50%;
        margin-left: 16px;
        background: var(--bg_button);
      }

      &-watched {
        margin-bottom: 12px;
        margin-right: 25px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: $font_size_medium;
        line-height: 22px;
        color: #fdfefd;
      }

      &-description {
        font-size: $font_size_normal;
        line-height: 19px;
        color: #b1b7cf;
        margin-bottom: 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        width: 100%;
      }

      &-time {
        font-size: $font_size_normal;
        line-height: 19px;
        color: #b1b7cf;
        margin-bottom: 0;
      }
    }
  }

  &-show {
    height: unset;
  }

  &-hide {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.read-all {
  padding: 10px 0 4px;
  font-size: $font_size_normal;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.header-event {
  cursor: pointer;
  // display: flex;
  padding: 7px;
  border: 1px solid #24345a;
  border-radius: 12px;

  &-active {
    position: absolute;
    right: 0;
    top: 0;
    width: 12px;
    height: 12px;
    background: var(--color_secondary);
    border: 0.4px solid #f1a57b;
    border-radius: 50%;
  }

  &-icon {
    width: 20px;
    height: 20px;
  }

  &-text {
    font-size: $font_size_small;
    line-height: 16px;
    color: #b1bedd;
    font-weight: 700;
    margin-left: 4px;
  }

  &-mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: none;
    background: var(--background_color_secondary);
    padding: 20px;

    @media #{$media-ipad} {
      display: flex;
    }

    &-icon {
      width: 24px;
      height: 24px;
      margin-right: 18px;
    }

    &-title {
      font-weight: 700;
      font-size: $font_size_normal;
      line-height: 24px;
      text-transform: uppercase;
      color: #fdfefd;
      margin-bottom: 0;
    }
  }

  &-container {
    position: absolute;
    z-index: 101;
    top: 43px;
    left: -400px;
    //right: -240px;
    background: var(--background_color_login);
    border-radius: 12px;
    transition: 0.3s;
    padding: 18px 16px 0;
    overflow-y: scroll;
    max-height: 516px;

    @media #{$media-tablet-vertical} {
      left: -400px;
      right: 0;
    }

    @media #{$media-ipad} {
      position: fixed;
      transition: none;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      max-height: 100vh;
      border-radius: 0;
      padding: 80px 16px 0;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &-title {
      font-weight: 400;
      font-size: $font_size_x_medium;
      line-height: 22px;
      // color: var(--color_secondary);
      margin-right: 9px;
      cursor: pointer;
      width: 49%;
      display: flex;
      justify-content: center;
    }

    &-number {
      min-width: 22px;
      line-height: 22px;
      font-weight: 700;
      font-size: $font_size_normal;
      color: var(--background_color_menu);
      padding: 0 5px;
      background: var(--bg_button);
      border-radius: 8px;
      text-align: center;
    }

    &-event {
      font-weight: 400;
      font-size: $font_size_x_medium;
      line-height: 22px;
      color: var(--text_color_primary);
      margin-right: 9px;
      cursor: pointer;
      width: 49%;
      display: flex;
      justify-content: center;
    }

    &-content {
      padding-bottom: 15px;
    }

    &-element {
      display: block;
      padding: 16px 12px;
      margin-bottom: 4px;
      border-radius: 4px;
      background: var(--text_color_placeholder);
      text-decoration: none;
      img {
        width: 100%;
        height: 100%;
        border-radius: 14px;
        background: #feb914;
        margin-right: 20px;
      }

      // display: flex;
      flex-direction: row;
      padding: 8px 25px;
      margin-bottom: 4px;
      border-radius: 4px;
      text-decoration: none;

      @media #{$media-tablet-vertical} {
        padding: 8px;
      }

      &-title {
        // margin-bottom: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-weight: 400;
        font-size: $font_size_medium;
        line-height: 22px;
        color: var(--color_secondary);
      }

      &-circle {
        height: 9px;
        min-width: 9px;
        border-radius: 50%;
        margin-left: 16px;
        background: var(--bg_button);
      }

      &-watched {
        margin-bottom: 12px;
        margin-right: 25px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: $font_size_medium;
        line-height: 22px;
        color: var(--color_secondary);
      }

      &-description {
        font-size: $font_size_normal;
        line-height: 19px;
        color: #b1b7cf;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin: 12px 0;
      }

      &-time {
        font-size: $font_size_normal;
        color: var(--color_secondary);
        margin-bottom: 0;
      }
    }
  }

  &-show {
    height: unset;
  }

  &-hide {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.text-active {
  color: var(--color_secondary) !important;
}
.customSeeMoreNotification {
  font-size: $font_size_small;
  color: white;
}
.notiCircle {
  height: 9px;
  min-width: 9px;
  border-radius: 50%;
  margin-left: 8px; 
  background: var(--bg_button);
}
