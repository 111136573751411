@import '../../../../../../assets/styles/helpers/variables';
@import '../../../styles/theme.scss';

.market {
  .activeMarketImage {
    opacity: 1 !important;
  }

  &Title {
    margin: 8px 16px;
    font-size: $font_size_normal;
    color: var(--color_secondary);

    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }

  &Options {
    @include display-flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 16px;

    &Filter {
      img {
        height: 13px;
        width: 12px;
        margin-right: 15px;
        opacity: 0.5;
        cursor: pointer;
      }
    }

    &Show {
      @include display-flex;
      align-items: center;

      &Decimal {
        position: relative;

        &:hover {
          .marketOptionsShowDecimalSelect {
            display: flex;
          }
        }

        span {
          font-weight: 600;
          font-size: $font_size_small;
          color: var(--text_color_primary);
        }

        &Arrow {
          margin-left: 4px;
        }

        &Select {
          position: absolute;
          padding: 8px 0;
          @include display-flex;
          flex-direction: column;
          background-color: rgb(30, 35, 41);
          right: 0;
          display: none;

          span {
            font-size: $font_size_normal;
            text-align: center;
            padding: 8px 16px;
            cursor: pointer;

            &:hover {
              background-color: var(--text_color_tertiary);
            }
          }
        }
      }

      &Dots {
        margin-top: 4px;
        margin-left: 12px;
      }
    }
  }

  &Parameter {
    @media only screen and (min-width: 1024px) {
      height: calc(100vh - 168px);
      overflow: auto;
      .marketParameter {
        -ms-overflow-style: none; /* khai báo sử dụng Internet Explorer, Edge */
        scrollbar-width: none; /* sử dụng Firefox */
        overflow-y: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .left {
      width: 33.33333%;
      text-align: left;
    }

    .right {
      width: 33.33333%;
      text-align: right;
    }

    &Header {
      @include display-flex;
      margin: 10px 16px 6px;

      p {
        font-weight: 500;
        font-size: $font_size_small;
        color: var(--text_color_primary);
      }
    }

    &Item {
      @include display-flex;
      padding: 2px 8px;
      margin: 0 8px;
      margin-bottom: 2px;

      p {
        font-weight: 400;
        font-size: $font_size_small;
        text-align: right;
        color: var(--text_color_primary);

        @media only screen and (max-width: 1024px) {
          font-size: $font_size_small;
        }
      }
    }

    &Value {
      @include display-flex;
      align-items: center;
      padding: 6px 8px;
      margin-bottom: 10px;
      border-bottom: 1px solid var(--border_color_primary);
      border-top: 1px solid var(--border_color_primary);

      &Main {
        font-weight: 400;
        font-size: $font_size_x_medium;
      }

      &Sub {
        font-weight: 500;
        font-size: $font_size_small;
        color: var(--text_color_secondary);
      }

      &Image {
        height: 20px;
        width: 20px;
        margin-right: 8px;
      }
    }
  }
  .loadingText {
    font-size: $font_size_small;
    color: var(--text_color_primary);
  }
}
