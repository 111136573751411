.modalVideoGuide {
  iframe {
    height: 500px;
    width: 620px;

    @media screen and (max-width: 768px) {
      width: 100%;
      max-height: calc(95vh - 90px);
      height: 600px;
    }
  }
}